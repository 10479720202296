import * as React from "react"


const PreviewInformation = ({title, data}) => {
    return (
        <>
            <div className={'information-preview'}>
                <div className={'col-4 d-flex flex-column justify-content-start mt-2'}>
                </div>
                <div className={'d-flex flex-column justify-content-start mt-2'}>
                    <h2 className={'text-title-preview mb-2'}>{title}</h2>
                    <div className={"d-flex container-information-thumbnail "}>
                        <div className={'d-flex flex-column justify-content-between mr-2 '}>
                            <div className={'d-flex preview-text'}>
                                 <span style={{color: 'grey'}}>{data.InformationProduction[0] ? `${data.InformationProduction[0].InformationLabel}: ` : null}</span>{data.InformationProduction[0] ? data.InformationProduction[0].InformationContent : null}</div>
                            <div className={"d-flex preview-text"}>
                                <span style={{color: 'grey'}}>{data.InformationProduction[2] ? `${data.InformationProduction[2].InformationLabel}: ` : null}</span>{data.InformationProduction[2]? data.InformationProduction[2].InformationContent : null}
                            </div>
                        </div>
                        <div className={'d-flex flex-column justify-content-between'}>
                            <div className={'d-flex preview-text'}>
                                 <span style={{color: 'grey'}}>{data.InformationProduction[1] ? `${data.InformationProduction[1].InformationLabel}: ` : null}</span>{data.InformationProduction[1] ? data.InformationProduction[1].InformationContent : null}
                            </div>
                            <div className={'d-flex preview-text'}>
                                <span style={{color: 'grey'}}>{data.InformationProduction[3] ? `${data.InformationProduction[3].InformationLabel}: ` : null}</span>{data.InformationProduction[3] ? data.InformationProduction[3].InformationContent : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PreviewInformation
