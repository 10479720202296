import React from "react";
import '../styles/components/blockProduct.css'
import VideoVignette from "./videoVignette";
import {Helmet} from "react-helmet";


const Ads = ({data, muteSound}) => {
    console.log(data.allStrapiAds)
    return (
        <>
            <Helmet>
                <title>Ebisu Records - Ads</title>
            </Helmet>
            <section className={'d-grid flex-wrap container-grid'}>
                {
                        data.allStrapiAds.edges.map((ads, index) => (
                            <VideoVignette
                                muteSound={muteSound}
                                key={ads.node.id}
                                slug={ads.node.slug}
                                imageSrc={ads.node.AdsImage.publicURL}
                                logoSrc={ads.node.AdsLogo.publicURL}
                                videoId={ads.node.videoId}
                                type={'Ads'}
                                title={ads.node.AdsTitle}
                                data={ads.node}/>
                        ))
                }
            </section>
        </>

    )
}

export default Ads
