import React from "react";
import {Link} from "gatsby";
import '../styles/components/blockProduct.css'
import {Helmet} from "react-helmet";


const Artists = ({data}) => {
    return (
        <>
            <Helmet>
                <title>Ebisu Records - Artists</title>
            </Helmet>
            <section className={'d-flex flex-wrap container-grid'}>
                {
                        data.allStrapiPageArtists.edges.map((artist) => (
                            <Link className={'container-block-product d-flex align-items-end mb-4 artist-default'}
                                  key={artist.node.id}
                                  to={`${artist.node.slug}`} style={{
                                textDecoration: 'none',
                                backgroundImage: `url(${artist.node.ArtistImage.publicURL})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}>
                                <img src={artist.node.ArtistLogo.publicURL} className={'text-artist p-2'}
                                     style={{width: '30%'}}
                                     alt={'Logo artist miniature'}/>
                            </Link>
                        ))
                }
            </section>
        </>

    )
}

export default Artists