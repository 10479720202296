import React, {useState} from "react";
import Vimeo from '@u-wave/react-vimeo';
import {Link} from "gatsby";
import PreviewInformation from "./previewIformation";

const VideoVignette = ({slug, imageSrc, logoSrc, videoId, type, title, data, muteSound}) => {
    const [paused, setPaused] = useState(true)
    const [activePreview, setActivePreview] = useState(false)
    let timer

    const handleMouseEnter = () => {
            timer = setTimeout(function () {
                setPaused(false)
                setActivePreview(true)
            }, 150)
    }
    const handleMouseLeave = () => {
        clearTimeout(timer)
        setPaused(true)
        setActivePreview(false)
    }

    return (
        <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={'vimeo-container d-flex align-items-end container-block-product position-relative'}
            to={`${slug}`} style={{
            textDecoration: 'none',
            backgroundImage: `url(${imageSrc})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative'
        }}>
            <img src={logoSrc}
                 className={'text-ads p-2 thumbnail-logo'}
                 style={{width: '30%'}}
                 alt={'Logo marque miniature'}

            />
            <Vimeo
                className={`video-preview ${activePreview ? '' : 'd-none'}`}
                // className={`video-preview`}
                // onMouseEnter={handleMouseEnter}
                video={videoId}
                responsive={true}
                paused={paused}
                controls={false}
                volume={muteSound? 1 : 0}
                // onPause={handlePause}
                // onPlay={handlePlay}
            />
            <PreviewInformation type={type} title={title} data={data}/>
            <div className={'background-link'}
                 style={{position: 'absolute', top: '0', left: '0', opacity: '0'}}><></>
            </div>
        </Link>
    )
}

export default VideoVignette
