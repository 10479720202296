import React from "react";
import '../styles/components/blockProduct.css'
import VideoVignette from "./videoVignette";
import {Helmet} from "react-helmet";


const Movies = ({data, muteSound}) => {
    return (
        <>
            <Helmet>
                <title>Ebisu Records - Movies</title>
            </Helmet>
            <section className={'d-flex flex-wrap container-grid'}>
                {
                      data.allStrapiPageMovies.edges.map((movies) => (
                            <VideoVignette
                                muteSound={muteSound}
                                key={movies.node.id}
                                slug={movies.node.slug}
                                imageSrc={movies.node.MoviesImage.publicURL}
                                logoSrc={movies.node.MoviesLogo.publicURL}
                                videoId={movies.node.videoId}
                                type={'Movies'}
                                title={movies.node.MoviesTitle}
                                data={movies.node} />

                        // </Link>
                    ))
                }
            </section>
        </>

    )
}

export default Movies
