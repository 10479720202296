import React, {useState} from 'react'
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {Link} from "gatsby";
import '../styles/pages/product.css'
import Header from "../components/header";
import Ads from "../components/ads";
import Movies from "../components/movies";
import Artists from "../components/artists";
import {Helmet} from "react-helmet";
import '../styles/theme/global.css'
import {Button} from "react-bootstrap";


function getParams() {
    if (typeof window !== 'undefined') {
        const queryString = window.location.pathname;
        return queryString
    }
}

getParams()

const PageProduct = ({data}) => {
    const chooserThemes = data.strapiThemeChooser
    const seo = data.strapiHomeSeo
    const [muteSound, setMuteSound] = useState(false)
    function handleMute() {
        setMuteSound(!muteSound)
    }

    return (
        <>
            <Helmet>
                <meta name={"description"} content={seo.DescriptionSeo}/>
                <meta property={"og:title"} content={seo.TitleSeo}/>
                <meta property={"og:type"} content={"website"}/>
                <meta property={'og:image'} content={`https://www.ebisurecords.fr${seo.ImageSeo.publicURL}`}/>
                <meta property={'og:description'} content={seo.DescriptionSeo}/>
                <meta property={"og:url"} content={'https://www.ebisurecords.fr'}/>
                <meta property={"og:site_name"} content={"ebisurecords"}/>
                <meta property={"og:locale"} content={"fr_FR"}/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content={seo.TitleSeo}/>
                <meta property="twitter:description" content={seo.DescriptionSeo}/>
                <meta property="twitter:image"
                      content={`https://www.ebisurecords.fr${seo.ImageSeo.publicURL}`}/>
            </Helmet>
            <Header data={data}/>
            <Layout className={'product-page'}>
                <section>
                    <nav className={'container-product-nav container-fluid'}>
                        <ul className={'ul-product-item'}
                            style={{listStyle: "none"}}>
                            {chooserThemes.Themes.map(navItem => (
                                <Link key={navItem.id} to={`/${navItem.slug}`}
                                      className={getParams() === `/${navItem.slug}` ? 'active-item' : 'item-product-container'}>
                                    <li className={'item-product'}>{navItem.title} </li>
                                </Link>
                            ))}
                        </ul>
                    </nav>

                    <section className={'container-fluid container-product'}>
                        {
                            getParams() === '/brands' ?
                                <Ads muteSound={muteSound} data={data}/>
                                :
                                null
                        }
                        {
                            getParams() === '/movies' ?
                                <Movies muteSound={muteSound} data={data}/>
                                :
                                null
                        }
                        {
                            getParams() === '/artists' ?
                                <Artists data={data} />
                                :
                                null
                        }
                    </section>
                        <Button onClick={handleMute} className={'position-fixed button-volume-list'}>{muteSound? <i className="fas fa-volume"/> :
                        <i className="fas fa-volume-mute"/> }</Button>
                </section>
            </Layout>
        </>
    )
}

export default PageProduct

export const ProductQuery = graphql`
query ProductQuery {
  strapiThemeChooser {
    Themes {
      button
      id
      title
      slug
      imageChooser {
        publicURL
      }
    }
  }
  strapiHomeSeo {
    ImageSeo {
      publicURL
    }
    DescriptionSeo
    TitleSeo
  }
  allStrapiContactEntries {
    edges {
      node {
        Email
        InscriptionCollecteEtNewsLetter
        Message
        Name
        Telephone
      }
    }
  }
  strapiHeader {
    id
    ItemsNav {
      Item
    }
    Logo {
      publicURL
    }
  }
   allStrapiAds (sort: {fields: Ordre, order:DESC}) {
    edges {
      node {
        videoId
        Video
        Director
        Producer
        SoundDesign
        id
        slug
        AdsImage {
          publicURL
        }
        InformationProduction {
          InformationContent
          InformationLabel
        }
        AdsTitle
        AdsLogo {
          publicURL
        }
        Ordre
      }
    }
  }
  allStrapiPageArtists (sort: {fields: Ordre, order:DESC}) {
    edges {
      node {
        id
        ArtistImage {
          publicURL
        }
        ArtistTitle
        ArtistLogo {
          publicURL
        }
        slug
        Ordre
      }
    }
  }
  strapiPageArtists {
    id
    slug
    ArtistImage {
      publicURL
    }
    ArtistLogo {
      publicURL
    }
    ArtistTitle
  }
  allStrapiPageMovies (sort: {fields: Ordre, order:DESC}) {
    edges {
      node {
        id
        slug
        MoviesTitle
        videoId
        Video
        Director
        Producer
        SoundDesign
        Ordre
        MoviesLogo {
          publicURL
        }
        InformationProduction {
          InformationContent
          InformationLabel
        }
        MoviesImage {
          publicURL
        }
      }
    }
  }
}
`
